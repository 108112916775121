import { SignatureDTO } from "@/common/dto/SignatureDTO";

export class FormDataWithSignatureDTO {
  data: Map<string, string>;
  signatures: SignatureDTO[];

  constructor(formData: Map<string, string>, signatures: SignatureDTO[]) {
    this.data = formData;
    this.signatures = signatures;
  }
}
