import { BaseClient } from "@/common/http/BaseClient";
import { DamageType } from "@/common/enums";

export class AnalyticsHttpClient extends BaseClient {
  private readonly loadAllAnalyticsPath = "/get/all";

  constructor() {
    super("/analytics");
  }

  async loadAllAnalytics(damageType: DamageType): Promise<object> {
    const url = this.urlWithId(this.loadAllAnalyticsPath, damageType);
    const req_config = this.createRequestConfig(url);

    const res = await this.axios.request(req_config);
    return res.data;
  }
}
